@import url('https://fonts.googleapis.com/css2?family=Geologica:wght@100..900&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

body {
  margin: 0;
  font-family: 'Plus Jakarta Sans' !important;
  font-size: larger;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.customfont {
  font-family: 'Geologica', sans-serif !important;
}

h1,
h2,
h3 {
  font-family: 'Geologica', sans-serif !important;
  font-weight: bolder;
}


table {
  border-radius: 32px !important;
}

thead,
th {
  font-weight: bold;
  background-color: rgb(231, 231, 231);
  padding: 8px;
  font-size: 16px;
  /* font-family: 'Geologica' !important; */
}

tr:nth-child(odd) {
  background-color: #f1efef;
}

td {
  font-family: 'Plus Jakarta Sans' !important;
  font-size: 16px !important;
  padding: 10px !important;
  text-align: center;
}

.InovuaReactDataGrid {
  min-height: 55vh !important;
  height: 100% !important;
}